import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { useImageQuery } from '../hooks/useImageQuery';
import { useSiteMetadata } from '../hooks/useSiteMetadata';
import Layout from '../templates/Layout';
import SEO from '../components/SEO';

const NotFound = () => {
  const { notFoundImage } = useImageQuery();
  const { primaryColor, primaryHover } = useSiteMetadata();

  return (
    <Layout>
      <SEO pageTitle="404 Not Found" />
      <section className="p-4">
        <figure>
          <Img
            fluid={notFoundImage.childImageSharp.fluid}
            alt="A wall"
            className="rounded-lg"
          />
          <figcaption className="mt-1 text-xs text-right text-gray-700 ">
            Photo by Patrick Tomasso on Unsplash
          </figcaption>
        </figure>
        <div className="py-10">
          <h1 className="mb-4 text-3xl font-extrabold text-center lg:text-5xl">
            No se encontró la página
          </h1>
          <p className="text-lg font-bold text-center">
            Favor de ir a la página de{' '}
            <Link
              to="/"
              className={`${primaryColor} hover:${primaryHover} transition-colors ease-in-out duration-500`}
            >
              inicio
            </Link>
            .
          </p>
        </div>
      </section>
    </Layout>
  );
};

export default NotFound;
